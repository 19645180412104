import React, {FunctionComponent} from 'react';

import {Translate} from '../../components/translations';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';

const CookiePolicy: FunctionComponent = () => (
  <Layout>
    <div className="content-wrapper">
      <PageHero title="COOKIE_POLICY_PAGE_TITLE" subtitle="COOKIE_POLICY_PAGE_SUBTITLE" />
      <Section>
        <div className="col-lg-12">
          <Translate name="COOKIE_POLICY_PAGE_CONTENT" />
        </div>
      </Section>
    </div>
  </Layout>
);

export default CookiePolicy;
